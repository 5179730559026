<template>
  <section class="medicos">
    <div class="linha"></div>
    <div class="texto">
      <h3>OLIGOCHECK</h3>
      <h2>BUSCAR CLÍNICA CERCA DE MI</h2>
      <p>Encuentra una clínica más cerca para realizarse un análisis con Oligocheck</p>
      <a href="#apresentacao">BUSCAR CLÍNICA</a>
      <div class="tag">
        <img src="@images/icones-pessoas.png">
        <h4>+ de 12.000 usuários en el mundo</h4>
      </div>
    </div>
    <img src="@images/medicos.png" class="medicos">
    <img src="@images/divisor-2.png" class="divisor">
  </section>
</template>

<script setup>

</script>

<style scoped>
  section.medicos {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 120px 80px 120px 80px;
    position: relative;
    background-image: url('@images/background.png');
    background-size: cover;
    background-position: center;
    overflow: hidden;
  }
  .texto {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    max-width: 600px;
  }
  .texto h3 {
    font-family: var(--regular);
    font-size: var(--f2);
    color: var(--cor-azul);
  }
  .texto h2 {
    font-family: var(--estilo);
    font-size: var(--f5);
    color: var(--cor-branco);
    width: 100%;
    max-width: 300px;
    margin: 5px 0 0 0;
  }
  .texto p {
    font-family: var(--regular);
    font-size: var(--f2);
    color: var(--cor-branco);
    line-height: 1.5;
    margin: 10px 0 20px 0;
  }
  a {
    font-family: var(--bold);
    font-size: var(--f2);
    color: var(--cor-branco);
    padding: 20px 40px;
    border-radius: 50px;
    background-color: var(--cor-ciano);
    transition: all 0.3s;
  }
  a:hover {
    background-color: var(--cor-ciano-escuro);
  }
  .tag {
    display: flex;
    align-items: center;
    margin: 50px 0 0 0;
  }
  .tag img {
    width: 100%;
    max-width: 100px;
    margin: 0 10px 0 0;
  }
  .tag h4 {
    font-family: var(--regular);
    font-size: var(--f2);
    color: var(--cor-branco);
  }
  .linha {
    position: absolute;
    top: -2px;
    left: 0;
    height: 20px;
    width: 100%;
    background: linear-gradient(90deg, var(--degrade));
  }
  img.medicos {
    width: 100%;
    max-width: 500px;
    position: absolute;
    bottom: -50px;
    right: 80px;
  }
  img.divisor {
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 100%;
  }
  @media screen and (max-width: 1000px) {
    section.medicos {
      flex-direction: column;
      padding: 80px 20px 400px 20px;
    }
    a {
      padding: 15px 30px;
    }
    .tag {
      margin: 30px 0 0 0;
    }
    .tag img {
      max-width: 60px;
    }
    .tag h4 {
      font-size: var(--f1);
    }
    img.medicos {
      max-width: 400px;
      right: 0;
    }
  }
</style>
