<!-- <template>
  <section class="apresentacao" id="apresentacao">
    <div class="conteudo">
      <div class="tag">
        <img src="@images/icones-pessoas.png" />
        <h3>+ de 12.000 usuários en el mundo</h3>
      </div>
      <h2>BUSCAR <span>CLÍNICA</span> CERCA DE MI</h2>
      <p>Encuentra una clínica más cerca para realizarse un análisis con Oligocheck</p>
      <div class="input">
        <img src="@images/icone-busca.svg" />
        <input
          type="text"
          placeholder="Ingrese su ciudad..."
          spellcheck="false"
          autocomplete="false"
        />
        <button>BUSCAR</button>
      </div>
    </div>
    <img src="@images/divisor.png" class="divisor" />
  </section>
</template> -->

<template>
  <section class="apresentacao" id="apresentacao">
    <div class="conteudo">
      <div class="tag">
        <img src="@images/icones-pessoas.png" />
        <h3>+ de 12.000 usuários en el mundo</h3>
      </div>
      <h2>BUSCAR <span>CLÍNICA</span> CERCA DE MI</h2>
      <p>Encuentra una clínica más cerca para realizarse un análisis con Oligocheck</p>
      <div class="input">
        <img src="@images/icone-busca.svg" />
        <input
          type="text"
          placeholder="Ingrese su país..."
          spellcheck="false"
          autocomplete="off"
          v-model="searchQuery"
        />
        <button @click="searchClinics">BUSCAR</button>
      </div>
    </div>
    <img src="@images/divisor.png" class="divisor" />
  </section>
</template>

<script setup>
import { ref } from "vue";
import { useRouter } from "vue-router";

const searchQuery = ref("");
const router = useRouter();

const searchClinics = () => {
  if (searchQuery.value.trim() !== "") {
    router.push({ name: 'busca', query: { city: searchQuery.value.trim() } });
  }
};
</script>

<style scoped>
section.apresentacao {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 160px 80px 150px 80px;
  position: relative;
  background-image: url("@images/background.png");
  background-size: cover;
  background-position: center;
}
.conteudo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 600px;
}
.tag {
  display: flex;
  align-items: center;
}
.tag img {
  width: 100%;
  max-width: 100px;
  margin: 0 10px 0 0;
}
.tag h3 {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-branco);
}
h2 {
  font-family: var(--estilo);
  font-size: var(--f5);
  color: var(--cor-branco);
  text-align: center;
  margin: 20px 0 10px 0;
}
h2 span {
  color: var(--cor-azul);
}
p {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-branco);
  text-align: center;
  line-height: 1.5;
  margin: 0 0 20px 0;
}
.input {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 500px;
  position: relative;
}
.input img {
  position: absolute;
  left: 25px;
  width: 100%;
  max-width: 15px;
}
.input input {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-branco);
  background-color: var(--cor-escuro-3);
  padding: 25px 100px 25px 50px;
  border-radius: 50px;
  width: 100%;
}
.input button {
  font-family: var(--bold);
  font-size: var(--f2);
  color: var(--cor-branco);
  padding: 0 50px;
  border-radius: 50px;
  background-color: var(--cor-ciano);
  position: absolute;
  height: 100%;
  right: 0;
  transition: all 0.3s;
}
.input button:hover {
  background-color: var(--cor-ciano-escuro);
}
img.divisor {
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 100%;
}
@media screen and (max-width: 1000px) {
  section.apresentacao {
    padding: 130px 20px 90px 20px;
  }
  .tag img {
    max-width: 70px;
  }
  .tag h3 {
    font-size: var(--f1);
  }
  h2 {
    font-size: var(--f4);
    width: 100%;
    max-width: 250px;
  }
  p {
    font-size: var(--f1);
    width: 100%;
    max-width: 270px;
  }
  .input img {
    left: 20px;
    max-width: 13px;
  }
  .input input {
    padding: 20px 100px 20px 45px;
  }
  .input button {
    font-size: var(--f1);
    padding: 0 25px;
  }
}
</style>
