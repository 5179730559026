<template>
  <section class="nav">
    <a href="/"> <img src="@images/logo-branco.svg" /></a>
    <a href="mailto:contacto@oligocheck.es">Correo de soporte</a>
    <a href="https://api.whatsapp.com/send/?phone=34623374628&text=He+visto+la+informaci%C3%B3n+en+el+sitio+institucional+y+quiero+m%C3%A1s+informaci%C3%B3n+sobre+Oligocheck&type=phone_number&app_absent=0">+34 623 37 46 28</a>
    <a href="https://api.whatsapp.com/send/?phone=34623374628&text=He+visto+la+informaci%C3%B3n+en+el+sitio+institucional+y+quiero+m%C3%A1s+informaci%C3%B3n+sobre+Oligocheck&type=phone_number&app_absent=0" class="destaque">ADQUIRIR OLIGOCHECK</a>
  </section>
</template>

<script setup></script>

<style scoped>
section.nav {
  display: flex;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 15px 80px;
  background: linear-gradient(90deg, #010d18, #022538);
  z-index: 5;
}
img {
  width: 100%;
  max-width: 130px;
}
a {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-branco);
  margin: 0 0 0 30px;
}
a.destaque {
  font-family: var(--bold);
  padding: 15px 25px;
  border-radius: 50px;
  background-color: var(--cor-ciano);
  margin-left: auto;
  transition: all 0.3s;
}
a.destaque:hover {
  background-color: var(--cor-ciano-escuro);
}
@media screen and (max-width: 1000px) {
  section.nav {
    padding: 15px 20px;
  }
  img {
    max-width: 100px;
  }
  a {
    display: none;
  }
  a.destaque {
    display: flex;
    font-size: var(--f1);
    padding: 13px 20px;
  }
}
</style>
