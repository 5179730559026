<template>
  <section class="clinicas">
    <div v-if="filteredClinics.length > 0" class="item-div">
      <div class="item" v-for="clinic in filteredClinics" :key="clinic.id">
        <div class="foto">
          <img :src="clinic.logo" alt="Foto da Clínica" />
        </div>
        <div class="nome">
          <h3>{{ clinic.name }}</h3>
          <p>Profesional acreditado</p>
        </div>
        <button class="telefone" @click="openLink(`tel:${clinic.contact}`)">
          <img src="@images/icone-telefone.svg" alt="Telefone" />
        </button>
        <button class="instagram" @click="openLink(clinic.instagram)">
          <img src="@images/icone-instagram.svg" alt="Instagram" />
        </button>
        <button
          class="whatsapp"
          @click="openLink(`https://wa.me/${formatWhatsAppNumber(clinic.whatsapp)}`)"
        >
          <img src="@images/icone-whatsapp-azul.svg" alt="WhatsApp" />
          <p>Whatsapp</p>
        </button>
      </div>
    </div>

    <div v-else class="conteudo">
      <h2>CUIDAD NO ENCONTRADA</h2>
      <h3>No hemos encontrado clínicas para la ciudad que buscabas. Intente otra búsqueda.</h3>
      <button @click="goBackToSearch">Buscar otra ciudad</button>
    </div>
  </section>
</template>

<script setup>
import { ref, onMounted, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { db } from "@/firebaseConfig";
import { collection, getDocs } from "firebase/firestore";

const clinics = ref([]);
const filteredClinics = ref([]);
const route = useRoute();
const router = useRouter();

const fetchClinics = async () => {
  try {
    const querySnapshot = await getDocs(collection(db, "clinics"));
    clinics.value = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
    filterClinics();
  } catch (error) {
    console.error("Erro ao obter clínicas: ", error);
  }
};

const filterClinics = () => {
  const searchQuery = route.query.city || "";
  if (searchQuery.trim() === "") {
    filteredClinics.value = clinics.value;
  } else {
    filteredClinics.value = clinics.value.filter((clinic) =>
      clinic.address.toLowerCase().includes(searchQuery.toLowerCase())
    );
  }
};

const openLink = (url) => {
  window.open(url, "_blank");
};

const formatWhatsAppNumber = (number) => {
  return number.replace(/\D/g, "");
};

const goBackToSearch = () => {
  router.push("/");
};

onMounted(() => {
  fetchClinics();
});

watch(
  () => route.query.city,
  () => {
    filterClinics();
  }
);
</script>
<style scoped>
section.clinicas {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px 80px 80px 80px;
  position: relative;
  background-color: var(--cor-escuro-2);
}

.item-div {
  width: 100%;
}
.item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--cor-escuro-3);
  border-radius: 10px;
  padding: 15px 170px 15px 15px;
  position: relative;
  width: 100%;
  margin: 0 0 20px 0;
}
.item .foto {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 55px;
  height: 55px;
  border: 3px solid var(--cor-azul);
  border-radius: 50%;
}
.item .foto img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}
.item button.whatsapp {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: var(--bold);
  font-size: var(--f1);
  color: var(--cor-azul);
  text-align: center;
  width: 100%;
  max-width: 150px;
  height: 100%;
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: var(--cor-escuro-4);
  border-radius: 10px;
  transition: all 0.3s;
}
.item button.whatsapp img {
  width: 100%;
  max-width: 15px;
  margin: 0 10px 0 0;
}
.item button.whatsapp:hover {
  background-color: var(--cor-escuro-5);
}
.item button.instagram {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  background-color: var(--cor-escuro-4);
  border-radius: 10px;
  margin: 0 0 0 20px;
  transition: all 0.3s;
}
.item button.instagram img {
  width: 100%;
  max-width: 15px;
}
.item button.instagram:hover {
  background-color: var(--cor-escuro-5);
}
.item button.telefone {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  background-color: var(--cor-escuro-4);
  border-radius: 10px;
  transition: all 0.3s;
}
.item button.telefone img {
  width: 100%;
  max-width: 15px;
}
.item button.telefone:hover {
  background-color: var(--cor-escuro-5);
}
.item .nome {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 0 0 15px;
  margin-right: auto;
}
.item .nome h3 {
  font-family: var(--bold);
  font-size: var(--f2);
  color: var(--cor-branco);
}
.item .nome p {
  font-family: var(--regular);
  font-size: var(--f1);
  color: var(--cor-branco);
  margin: 5px 0 0 0;
}

.conteudo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 700px;
}

.conteudo  h3 {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-branco);
}
.conteudo  h2 {
  font-family: var(--estilo);
  font-size: var(--f5);
  color: var(--cor-branco);
  text-align: center;
  margin: 20px 0 10px 0;
}
.conteudo  span {
  color: var(--cor-azul);
}
.conteudo button {
  margin-top: 20px;
  font-family: var(--bold);
  padding: 15px 25px;
  border-radius: 50px;
  background-color: var(--cor-ciano);
  color: white;
  border: none;
  cursor: pointer;
}

.conteudo button:hover {
  background-color: var(--cor-ciano-escuro);
}
@media screen and (max-width: 1000px) {
  section.clinicas {
    padding: 50px 20px 60px 20px;
  }
  .item {
    flex-wrap: wrap;
    justify-content: flex-start;
    padding: 20px;
  }
  .item .foto {
    margin: 0 0 20px 0;
  }
  .item button.whatsapp {
    padding: 10px 20px;
    margin: 0 0 0 10px;
    height: 50px;
    width: auto;
    max-width: auto;
    position: relative;
    bottom: auto;
    right: auto;
  }
  .item button.instagram {
    margin: 0 0 0 10px;
  }
  .item .nome {
    padding: 0 0 20px 15px;
    margin-right: 0;
    width: calc(100% - 55px);
  }
}
</style>
