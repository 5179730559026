<template>
  <section class="apresentacao" id="apresentacao">
    <div class="conteudo">
      <div class="tag">
        <img src="@images/icones-pessoas.png" />
        <h3>+ de 12.000 usuários en el mundo</h3>
      </div>
      <h2>CLÍNICAS CERCA DE <span>SU DIRECCIÓN</span></h2>
      <div class="busca">
        <img src="@images/icone-localizacao.svg" />
        <p>{{ city }}</p>
      </div>
    </div>
    <img src="@images/divisor.png" class="divisor" />
  </section>
</template>

<script setup>
import { ref, onMounted } from "vue";
import { useRoute } from "vue-router";

const route = useRoute();
const city = ref("");

onMounted(() => {
  city.value = route.query.city || "Cidade não especificada";
});
</script>

<style scoped>
section.apresentacao {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 140px 80px 120px 80px;
  position: relative;
  background-image: url("@images/background.png");
  background-size: cover;
  background-position: center;
}
.conteudo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 700px;
}
.tag {
  display: flex;
  align-items: center;
}
.tag img {
  width: 100%;
  max-width: 100px;
  margin: 0 10px 0 0;
}
.tag h3 {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-branco);
}
h2 {
  font-family: var(--estilo);
  font-size: var(--f5);
  color: var(--cor-branco);
  text-align: center;
  margin: 20px 0 10px 0;
}
h2 span {
  color: var(--cor-azul);
}
.busca {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px 0 0 0;
}
p {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-branco);
  text-align: center;
  line-height: 1.5;
  margin: 0 0 0 10px;
}
img.divisor {
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 100%;
}

@media screen and (max-width: 1000px) {
  section.apresentacao {
    padding: 110px 20px 60px 20px;
  }
  .tag img {
    max-width: 70px;
  }
  .tag h3 {
    font-size: var(--f1);
  }
  h2 {
    font-size: var(--f4);
    width: 100%;
    max-width: 250px;
  }
  p {
    font-size: var(--f1);
  }
}
</style>
