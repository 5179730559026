<template>
  <section class="clinicas">
    <div class="titulo">
      <div class="texto">
        <h3>Consulte las clínicas acreditadas</h3>
        <p>Reserve su cita con una clínica</p>
      </div>
      <div class="botoes">
        <button class="avancar" @click="voltarSlide">
          <img src="@images/icone-seta.svg" alt="Voltar" />
        </button>
        <button class="voltar" @click="avancarSlide">
          <img src="@images/icone-seta.svg" alt="Avançar" />
        </button>
      </div>
    </div>
    <div class="slide" ref="slide">
      <div class="item" v-for="clinic in clinics" :key="clinic.id">
        <div class="foto">
          <img :src="clinic.logo" :alt="clinic.name" />
        </div>
        <div class="nome">
          <h3>{{ clinic.name }}</h3>
          <p>Profesional acreditado</p>
        </div>
        <button @click="openModal(clinic)">Agendar una cita</button>
      </div>
    </div>
    <div v-if="showModal" class="modal-overlay">
      <div class="modal">
        <button class="close" @click="closeModal">X</button>
        <div class="modal-content">
          <div class="clinic-info">
            <h3>{{ selectedClinic.name }}</h3>
          </div>
          <button class="address">
            <img src="@images/icone-localizacao.svg" alt="Endereço" />
            {{ selectedClinic.address }}
          </button>
          <button class="telefone" @click="openLink(`tel:${selectedClinic.contact}`)">
            <img src="@images/icone-telefone.svg" alt="Telefone" />
            {{ selectedClinic.contact }}
          </button>

          <button class="instagram" @click="openLink(selectedClinic.instagram)">
            <img src="@images/icone-instagram.svg" alt="Instagram" />
            {{ extractInstagramUsername(selectedClinic.instagram) }}
          </button>

          <button
            class="whatsapp"
            @click="openLink(`https://wa.me/${formatWhatsAppNumber(selectedClinic.whatsapp)}`)"
          >
            <img src="@images/icone-whatsapp-azul.svg" alt="WhatsApp" />
            <p>WhatsApp</p>
          </button>


        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import { ref, onMounted } from "vue";
import { db } from "@/firebaseConfig";
import { collection, getDocs } from "firebase/firestore";

const clinics = ref([]);
const showModal = ref(false);
const selectedClinic = ref(null);
const slide = ref(null);

const larguraQuadro = 360;

const fetchClinics = async () => {
  try {
    const querySnapshot = await getDocs(collection(db, "clinics"));
    clinics.value = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
  } catch (error) {
    console.error("Erro ao obter clínicas: ", error);
  }
};

const avancarSlide = () => {
  if (slide.value) {
    slide.value.scrollBy({ left: larguraQuadro, behavior: "smooth" });
  }
};

const voltarSlide = () => {
  if (slide.value) {
    slide.value.scrollBy({ left: -larguraQuadro, behavior: "smooth" });
  }
};

const openModal = (clinic) => {
  selectedClinic.value = clinic;
  showModal.value = true;
};

const closeModal = () => {
  showModal.value = false;
};

const openLink = (url) => {
  window.open(url, "_blank");
};

const formatWhatsAppNumber = (number) => {
  return number.replace(/\D/g, "");
};


const formatWhatsAppLink = (whatsappUrl) => {
  if (!whatsappUrl) return "https://wa.me"; // Retorna link padrão se estiver vazio

  // Corrigir domínio errado de "was.me" para "wa.me"
  const correctedUrl = whatsappUrl.replace("was.me", "wa.me");

  // Verificar se o link já está no formato correto
  if (correctedUrl.startsWith("https://wa.me/")) {
    return correctedUrl;
  }

  // Extrair números e formatar corretamente
  const match = correctedUrl.match(/(\d+)/); // Apenas números
  return match ? `https://wa.me/${match[0]}` : "https://wa.me";
};



const extractInstagramUsername = (instagramUrl) => {
  try {
    const match = instagramUrl.match(/instagram\.com\/([^/?]+)/);
    return match ? `@${match[1]}` : "Instagram";
  } catch (e) {
    return "Instagram";
  }
};

onMounted(() => {
  fetchClinics();
});
</script>

<style scoped>
section.clinicas {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px 0 80px 0;
  position: relative;
  background-color: var(--cor-escuro-2);
}
.titulo {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0 80px;
}
.titulo .texto {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.titulo .texto h3 {
  font-family: var(--bold);
  font-size: var(--f3);
  color: var(--cor-branco);
}
.titulo .texto p {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-branco);
  margin: 5px 0 0 0;
}
.titulo .botoes {
  display: flex;
  align-items: center;
}
.titulo .botoes button {
  width: 60px;
  height: 60px;
  border-radius: 10px;
  background-color: var(--cor-escuro-3);
  transition: all 0.3s;
}
.titulo .botoes button:hover {
  background-color: var(--cor-escuro-4);
}
.titulo .botoes button.voltar {
  margin: 0 0 0 10px;
}
.titulo .botoes button.voltar img {
  transform: rotate(-180deg);
}
.titulo .botoes button img {
  width: 100%;
  max-width: 10px;
}
.slide {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  padding: 40px 80px 0 80px;
  overflow: hidden;
  scroll-behavior: smooth;
}
.slide .item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: var(--cor-escuro-3);
  border-radius: 10px;
  padding: 25px 25px 80px 25px;
  position: relative;
  width: 320px;
  min-width: 320px;
  max-width: 320px;
  margin: 0 20px 0 0;
}
.slide .item .foto {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 55px;
  height: 55px;
  border: 3px solid var(--cor-azul);
  border-radius: 50%;
}
.slide .item .foto img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}
.slide .item button {
  font-family: var(--bold);
  font-size: var(--f2);
  color: var(--cor-azul);
  padding: 20px 0;
  text-align: center;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: var(--cor-escuro-4);
  border-radius: 10px;
  transition: all 0.3s;
}
.slide .item button:hover {
  background-color: var(--cor-escuro-5);
}
.slide .item .nome {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 0 0 15px;
}
.slide .item .nome h3 {
  font-family: var(--bold);
  font-size: var(--f2);
  color: var(--cor-branco);
}
.slide .item .nome p {
  font-family: var(--regular);
  font-size: var(--f1);
  color: var(--cor-branco);
  margin: 5px 0 0 0;
}
@media screen and (max-width: 1000px) {
  section.clinicas {
    padding: 60px 0 80px 0;
  }
  .titulo {
    flex-direction: column;
    padding: 0 20px;
  }
  .titulo .texto {
    align-items: center;
    text-align: center;
  }
  .titulo .texto p {
    margin: 10px 0 0 0;
  }
  .titulo .botoes {
    display: none;
  }
  .slide {
    overflow: scroll;
    padding: 40px 20px 0 20px;
    scrollbar-width: none;
  }
  .slide::-webkit-scrollbar {
    display: none;
  }
}
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/* Modal box */
.modal {
  border-radius: 10px;
  width: 400px;
  max-width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  padding: 40px 0 80px 0;
  position: relative;
  background-color: var(--cor-escuro-5);
}

.modal .close {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 16px;
  font-family: var(--regular);
  background: none;
  border: none;
  color: white;
  cursor: pointer;
}

.modal .modal-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  row-gap: 10px;
}

.modal button.whatsapp,
.modal button.instagram ,
.modal button.address,
.modal button.telefone
{
  max-width: 340px;
}
.modal button.whatsapp {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: 100%;
  height: 50px;
  background-color: var(--cor-escuro-4);
  border-radius: 10px;
  margin: 0 0 0 20px;
  transition: all 0.3s;
  font-family: var(--regular);
  color: white;
  font-size: 14px;
}
.modal button.whatsapp img {
  width: 100%;
  max-width: 15px;
}
.modal button.whatsapp:hover {
  background-color: var(--cor-escuro-5);
}
.modal button.instagram {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 50px;
  background-color: var(--cor-escuro-4);
  border-radius: 10px;
  margin: 0 0 0 20px;
  transition: all 0.3s;
  gap: 10px;
  font-family: var(--regular);
  color: white;
  font-size: 14px;
}
.modal button.instagram img {
  width: 100%;
  max-width: 15px;
}
.modal button.instagram:hover {
  background-color: var(--cor-escuro-5);
}
.modal button.telefone,
.modal button.address {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 50px;
  background-color: var(--cor-escuro-4);
  border-radius: 10px;
  margin: 0 0 0 20px;
  transition: all 0.3s;
  gap: 10px;
  font-family: var(--regular);
  color: white;
  font-size: 14px;
}
.modal button.address {
  font-size: 13px;
  padding: 10px;
}
.modal button.address  img {
  width: 100%;
  max-width: 14px;
}

.modal button.telefone img{
  width: 100%;
  max-width: 15px;
}
.modal button.telefone:hover {
  background-color: var(--cor-escuro-5);
}
.modal .nome {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 0 0 15px;
  margin-right: auto;
}
.modal h3 {
  font-family: var(--bold);
  font-size: var(--f2);
  color: var(--cor-branco);
}
</style>
